
 .custom-modal-dialog{
    display: flex;
    margin-top: 20px;
    justify-content: flex-end;
    
    margin-right: -10px;
  }
  
  .custom-modal-dialog button {
    margin-left: 16px;
  }


  .custom-modal-dialog button:is(.purge-button) {
    background-color: red;
    font-weight: bold;
    color: white;
  }

  .custom-modal-dialog button:is(.purge-button):hover {
    background-color: rgb(170, 8, 8);
  }

  .custom-modal-dialog button:is(.ban-button) {
    background-color: #e67e22;
    color: white;
  }

  .custom-modal-dialog button:is(.ban-button):hover {
    background-color: #c36c1f
  }
  
  .custom-modal-dialog button:hover {
    background-color: rgb(167, 167, 167)
  }