.login_form,
.resend_email_form,
.status {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.login_form {
  color: white;
  width: 100%;
}

.create_account{
  width: 100%;
}

.login_form > * {
  margin-bottom: 20px !important;
}
.resend_email_form {
  margin: 10px;
  height: 80px;
}
.status {
  font-weight: bold;
  color: white;
  text-align: center;
}
.login_form input,
.resend_email_form input {
  max-width: 300px;
}

.login_transition-enter {
  opacity: 0;
}
.login_transition-enter-active {
  opacity: 1;
  transition: opacity 200ms;
}
.login_transition-exit {
  opacity: 1;
}
.login_transition-exit-active {
  opacity: 0;
  transition: opacity 200ms;
}

.login_button_text {
  font-size: 12px;
  font-style: italic;
  color: white;
}

.login_button_text:hover {
  cursor: pointer;
  text-decoration: underline;
}

.confirm_screen,
.login_screen {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  width: 100%;
  height: 100%;
}

.confirm_screen .window_pop_up,
.login_screen .window_pop_up{
  max-width: 800px;
  min-height: 500px;
}

@media (min-width: 884px){
  .login_screen {
    padding-left: 290px;
  }
}

@media (max-width: 883px){
  .login_controls {
    display: none;
  }
}

.login_content{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow: scroll;
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For Internet Explorer and Edge */
}

.login_content::-webkit-scrollbar {
  display: none !important;
}

.login_header{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 200px;
  padding-top: 20px;
}

.login_logo{
  width: 75%;
  max-width: 500px;
  min-width: 200px;
  max-height: 300px;
  object-fit:contain;
}

@media (max-width: 476px) {
  .login_logo{
    min-width: 200px;
    max-width: 300px;
    padding: 0;
    padding-top: 20px;
  }
}

.login_form button {
  background-color: #e67e22;
  width: 100%;
  max-width: 300px;
}

.login_form button:hover {
  background-color: #c36c1f
}

.login_content{
  .nice_container {
    width: 100%;
    max-width: 300px;
  }

  .nice_container p{
    padding-bottom: 6px;
    font-size: 12px;
    color: antiquewhite;
    text-transform: capitalize;
  }

  .nice_textbox_login{
    width: 100%;
    font-family: "Ubuntu", "Helvetica", "Arial", "FreeSans", sans-serif;
    padding: 10px;
    font-size: 14px;
    color: rgb(218, 218, 218);
    border-radius: 4px;
    background-color: transparent;
    border-color: transparent;
  }

   .nice_textbox_login:focus,
  .nice_textbox_login:active {

    outline-width: 1px;
    outline-color: #ffffff0a;
  }


  .nice_textbox_login_container{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    background-color: #3f3e3e;
    border-radius: 4px;
    border-color: white;
    border: 1px solid #ffffff86;
  }

  .nice_textbox_login_container svg {
    position: absolute;
    margin-right: 10px;
  }

    .nice_textbox_login:-webkit-autofill,
    .nice_textbox_login:-webkit-autofill:hover, 
    .nice_textbox_login:-webkit-autofill:focus, 
    .nice_textbox_login:-webkit-autofill:active{
      background-color: #3f3e3e !important;
      border-color: transparent !important;
    }

    .nice_textbox_login:-webkit-autofill,
    .nice_textbox_login:-webkit-autofill:hover, 
    .nice_textbox_login:-webkit-autofill:focus, 
    .nice_textbox_login:-webkit-autofill:active{
    -webkit-background-clip: text;
    -webkit-text-fill-color: rgb(218, 218, 218);
    background-color: #3f3e3e !important;

  }

  @media (min-width: 884px) {
    .login_button_text{
      display: none;
    }
  }


  ::-ms-input-placeholder { 
    color: rgb(218, 218, 218);
  }
}