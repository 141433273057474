.nav {
  width: 100%;
  background-color: #333333;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
  z-index: 2;
  box-shadow: 2px 2px 4px -1px rgba(0, 0, 0, 0.4),
  1px 4px 5px 0px rgba(0, 0, 0, 0.4), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  /* box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.411); */
}
.nav a {
  color: inherit;
}
.nav_links {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  max-width: 100%;
  flex-wrap: wrap;
}

.nav_active {
  background-color: rgba(228, 228, 220, 0.116);
}

.logo_discord{
  height: 20px;
}

.login_menu {
  height: 50px;
  margin: 0px 5px 0px 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav_block {
  color: white;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px 15px 5px 15px;
  text-align: center;
  height: 40px;
}

.nav_block:first-child > img {
  max-width: 250px;
  width: 30vw;
}

.nav .MuiButton-root {
  color: white;
  font-size: 16px;
  letter-spacing: 1.5px;
  font-family: inherit;
}
.drawer_nav .MuiButton-label {
  color: white;
}

@media (max-width: 1280px) {
  .nav_block {
    margin: 5px 5px 5px 5px;
  }
  .nav_block:first-child > img {
    max-width: 20vw;
  }
}

@media (max-width: 1000px) {
  .nav_block {
    margin: 5px 5px 5px 5px;
  }
  .nav_block .MuiButton-text,
  .nav_block .MuiButton-root {
    padding: 6px 2px;
    font-size: 13px;
  }
}

.unread_messages {
  color: #e69344;
  display: flex;
  font-weight: 600;
}

.empty_space{
  margin-left: 290px;
  height: 20px;
}

.menu_popper{
  display: none;
}

@media (max-width: 883px) {
  .menu_popper{
    display: unset;
  }
  .nav_block {
    margin: 2px 2px 2px 2px;
  }
  .nav_block .MuiButton-text,
  .nav_block .MuiButton-root {
    padding: 3px 1px;
    font-size: 14px;
  }
  .nav_block:first-child > img {
    display: none;
  }

  .nav  {
    margin-left: 0px;
  }

  .empty_space{
    margin-left: 0px;
    height: 20px;
  }
}
