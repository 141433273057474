.posts .post,
.post_answered {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: #2c2b2bcd;
  backdrop-filter: blur(8px);
  margin: 5px;
  padding: 5px;
  width: 90%;
  height: auto;
  position: relative;
  color: white;
  border-radius: 4px;
  box-shadow: 6px 6px 4px -1px rgba(0, 0, 0, 0.4);
}

.posts {
  max-width: 1000px;
}

.post:hover,
.post_answered:hover,
.post_in_progress:hover {
  position: relative;
  top: 2px;
}

.post_answered {
  outline: 2px solid #2ecc71;
}

.post_in_progress {
  outline: 2px solid #eed2589d;
}

.post_hidden {
  opacity: 0.7;
}

.post_pinned {
  /* background-color: #31363acd !important; */
  background-color: #1c232bcd !important;
}

.post_upvotes {
  width: 120px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.post_upvotes a {
  font-size: 10px;
  color: white;
}

b {
  font-weight: 700;
}

.posted_by a {
  color: #e69344;
}

.posted_by b {
  font-size: 10px;
  color: white;
}

.posted_by:hover{
  opacity: 0.8;
  margin-top: -2px;
}

.post_upvotes > p {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  font-weight: 600;
  height: 30px;
}

.post_contents {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  height: 100%;
  padding-left: 5px;
  padding-right: 8px;
  width: 100%;
  max-width: calc(100% - 100px);
}


.comments_number {
  display: flex;
  align-items: center;
  position: absolute;
  right: 18px;
  bottom: 10px;
  font-size: 12px;
  color: white;

  span{
    margin-top: 3px;
    margin-right: -2px;
  }
}

.version_number {
  font-size: 12px;
  padding-left: 2px;
  margin-top: 5px;
  color: grey;
}

.post_list_post_controls {
  position: absolute;
  right: 5px;
  top: 5px;
}

.post_contents h1 {
  font-size: 18px;
  font-weight: 800;
  letter-spacing: 1.2px;
  color: white;
  overflow: hidden;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 1.2;
}

.post_title,
.post_title a,
.post_title h1 {
  width: 100%;
}

.post_title {
  height: 19px;
  display: flex;
}

.post_title_div {
  overflow: hidden;
}
.post_list_controls_div {
  flex-grow: 2;
  display: flex;
  align-items: center;
  height: 19px;
}

.post_list_controls_div .date_time_by {
  font-size: 12px;
  white-space: nowrap;
  margin-left: 6px;
}

.post_list_controls_div .controls_post_title {
  .MuiIconButton-sizeSmall{
    margin-right: -10px;
  }
}

.post_contents h1:hover {
  cursor: pointer;
  text-decoration: underline;
  opacity: 0.9;
}

.post_contents > div {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

.post_contents > div > * {
  margin: 0px 2px 0px 2px;
}

.post_status {
  background-color: #34495e;
  padding: 3px;
  border-radius: 18px;
}

.post_category {
  background-color: #e67e22;
  padding: 3px 5px 3px 5px;
  color: white;
  font-size: 12px;
  cursor: pointer;
  margin: 0px 4px 0px 0px;
}

.rrui_tooltip {
  display: none !important;
}

.post_content_body {
  margin-top: 5px;
  height: 14px;
  width: calc(100% - 30px) !important;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: flex-start !important;
}

.post_content_body p {
  color: white;
  text-overflow: ellipsis;
  font-weight: 100;
  font-size: 13px;
  white-space: nowrap;
}

.post_upvotes .posted_by {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: right;
  align-items: flex-end;
  padding-right: 12px;
  padding-top: 5px;
}

@media (max-width: 899px) {
  .post_upvotes .posted_by {
    max-width: 100% !important;
    padding-right: 20px;
  }
}
