.window_pop_up{
  background-color: rgba(0, 0, 0, 0.5);
  width: 70%;
  min-height: 60%;
  max-height: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-shadow: 6px 6px 4px -1px rgba(0, 0, 0, 0.4);
  z-index: 1;
  -ms-overflow-style: none !important;  /* IE and Edge */
  scrollbar-width: none !important;  
}


.window_pop_up::-webkit-scrollbar {
  display: none !important;
}

.windows_pop_up_title{
  height: 54px;
  display: flex;
  align-items: center;
  font-size: 20px;
  color: white;
  font-weight: 700;
  background-color: #333333;
}

.logo_window{
  padding: 10px;
  margin-left: 10px;
  margin-right: 5px;
}


@media (max-width: 500px) {
  .window_pop_up{
    width: calc(100% - 10%);
  }
}

@media (max-width: 376px) {
  .windows_pop_up_title{
    display: none;
  }

  .window_pop_up{
    background-color: transparent;
    box-shadow: none;
    padding-top: 54px;
  }
}