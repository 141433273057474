.post_list_filters {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.post_list_filters > p {
  font-weight: 600;
}

.post_list_pagination > div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.post_list_pagination {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  height: 50px;
  width: 200px;
}
.post_list_search,
.post_list_search input {
  width: 100%;
  max-width: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.post_controls_latests {
  display: flex;
  align-items: center;
  background-color: #333333;
  width: 100%;
  color: white;
  flex-direction: column;
  padding: 5px;
  justify-content: space-between;
}
.post_list_filters {
  text-transform: uppercase;
}

.post_controls_latests_upper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 5px;
  width: 100%;
}
.post_controls_latests_upper > * {
  margin-right: 5px;
}
.post_controls_latests_upper select {
  background-color: #3f3e3e;
  border: none;
  color: white;
  font-size: 16px;
  padding: 2px;
}
.post_controls_latests_upper select:after {
  border-color: white;
}

.post_controls_latests_upper select:focus {
  outline: 2px solid rgba(0, 0, 0, 0.5);
}
.post_list_filters form {
  display: flex;
  flex-wrap: wrap;
}
.post_list_filters > form > label {
  width: 60px;
  display: flex;
  justify-items: center;
  align-items: center;
}
.post_controls_latests_lower {
  width: 100%;
}

.post_controls_latests_lower input[type="text"] {
  width: 100%;
  background-color: #3f3e3e;
  border: 1px solid transparent;
  color: white;
  font-size: 18px;
  line-height: 27px;
}
.post_controls_latests_lower input[type="text"]::-webkit-input-placeholder {
  position: relative;
  top: 1px;
}

.post_searchbar {
  position: relative;
}
.post_searchbar input {
  text-indent: 30px;
}
.post_searchbar svg {
  position: absolute;
  top: 3.5px;
  left: 5px;
  font-size: 15px;
}
.mobile_control_drawer {
  text-align: center;
}
.mobile_control_drawer svg {
  cursor: pointer;
}

.mobile_control_drawer .post_list_filters {
  margin-top: 5px;
}
@media (max-width: 550px) {
  .mobile_control_drawer .mobile_drawer_divider select {
    width: 20vw;
  }
}
@media (max-width: 280px) {
  .post_controls_latests_upper {
    height: 150px !important;
  }
}
.mobile_drawer_container {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
.mobile_drawer_container select {
  margin: 5px;
  padding: 5px;
}
.mobile_drawer_divider {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
}

@media (max-width: 500px) {
  .post_list_filters {
    margin-top: 5px;
  }
}

.scroll_to_top {
  cursor: pointer;
  position: fixed;
  bottom: 10px;
  left: 10px;
  background-color: #333333;
  border-radius: 3px;
  z-index: 3;
  border: 1px solid white;
}
.scroll_to_top:hover {
  opacity: 0.9;
}

@media (max-width: 930px) {
  .scroll_to_top {
    display: flex;
    left: 50%;
  }
}

@media (max-width: 883px) {
  .post_controls_latests_upper {
    flex-direction: column;
  }
  .post_controls_latests {
    display: none;
  }
}


@media (min-width: 884px) {
  .post_controls_latests {
    background-color: transparent;
    background-color: transparent !important;
    padding: 0 !important;
  }
}