.inbox_message {
  width: 100%;
  background-color: #2c2b2bcd;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 10px;
  color: white;
  border-radius: 4px;
  box-shadow: 6px 6px 4px -1px rgba(0, 0, 0, 0.4);
}

.inbox_message:hover {
  position: relative;
  top: 2px;
}

.inbox_message > div {
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
}

.inbox_message_header {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-weight: 600;
}

.inbox_message_header p{
  margin-top: 14px;
  margin-bottom: 8px;
  font-size: 14px;
  font-weight: 300;
  display: flex;

  /* span {
    background-color: green;
  } */
}

.inbox_message hr {
  width: 100%;
}

.inbox_message_controls {
  display: flex;
  justify-content: flex-end;
  flex-grow: 1;
  
}
.inbox_message_controls > div {
  margin-right: 10px;
  font-weight: 600;
  color: #e69344;
}

.inbox_control:hover {
  text-decoration: underline;
  cursor: pointer;
}

.inbox_date_header{
  display: flex;
}

.inbox_date_slash{
  margin-left: 5px;
  margin-right: 5px;
}

@media (max-width: 600px) {

  .inbox_date_header{
    flex-direction: column;
  }
  .inbox_date_slash{
    opacity: 0;
    height: 10px;
  }
  .inbox_message .inbox_message_header p{
    display: flex;
    flex-direction: column;
  }

  .inbox_message .inbox_message_controls{
    justify-content: flex-start;
    padding-top: 20px;
  }

  .inbox_message .post_main_body{
    padding: 0;
    padding-top: 20px;
  }
}