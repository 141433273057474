.feed {
  bottom: 0%;
  right: 0%;
  position: fixed;
  overflow-y: hidden;
  overflow-x: hidden;
  max-width: 200px;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  width: 100%;
  color: #fff;
  background-color: #3f3e3e;
  z-index: 1;
}
.feed_header {
  background-color: #e69344;
  cursor: pointer;
}

.feed_header {
  padding: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  position: relative;
}
.feed_blobs {
  padding: 10px;
}
.mini_icon {
  position: absolute;
  right: 5px;
  top: 1px;
}
.mini_icon:hover {
  top: 2px;
}

.feed > div {
  position: relative;
}
.feed_blob {
  margin-bottom: 10px;
  border: 1px solid black;
  line-height: 1.2;
  font-size: 12px;
  background-color: #333333;
}

.feed_blob:hover {
  background-color: #3f3e3e;
}

.feed_blob a {
  width: 100%;
  height: 100%;
  display: block;
  padding: 5px;
  color: white;
}
.feed_blob > a > div {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid rgb(155, 152, 152);
  margin-bottom: 5px;
}

.feed_blob b {
  font-weight: 600;
  max-width: 100px;
  text-overflow: ellipsis;
  overflow: hidden;
}
.feed_blob_text {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.feed h1 {
  font-weight: 600;
  text-decoration: underline;
}
.feed_min h1 {
  text-align: left;
  margin-bottom: 0px;
}

.feed_blob:last-child {
  margin-bottom: 0px;
}

@media (max-width: 800px) {
  .feed {
    display: none;
  }
}
