.category_data {
  margin: auto;
  max-width: 1000px;
  width: 90%;
}
.category_data_body {
  background-color: #333333;
  padding: 20px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin: 10px 0px 10px 0px;
}
.category_search_field {
  margin: auto;
  width: 100%;
}

.category_search_field input {
  width: 100%;
}
.cat_data {
  display: flex;
  justify-content: flex-start;
  background-color: rgba(228, 226, 226, 0.185);
  height: 50px;
  margin-right: 10px;
  margin-bottom: 10px;
  align-items: center;
  max-width: 150px;
  padding: 10px;
  color: white;
}
.cat_data > p {
  margin-right: 5px;
}
.cat_data > .category {
  margin: 0px;
}
