.leaderboard_list {
  max-width: 1000px;
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
}

.leaderboard_list table {
  color: white;
  display: flex;
  justify-content: center;
  width: 100%;
  z-index: 1;
}

.leaderboard_screen {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: auto;
  width: 100%;
  height: 100%;
}

.leaderboard_list table tbody{
  width: 100%;
  display: flex;
  flex-direction: column;
}

.leaderboard_list table .user_table_item_selected td,
.leaderboard_list table .user_table_item td {
  padding: 30px 20px 30px 20px;
  text-align: center;
  font-size: 20px;
  font-weight: 600;

  a{
    color: white !important;
  }
}

.user_table_item_selected:hover,
.user_table_item:hover {
  position: relative;
  top: 2px;
}

.user_table_item_selected,
.user_table_item{
  box-shadow: 6px 6px 4px -1px rgba(0, 0, 0, 0.4);
  border-radius: 8px;
  margin: 10px;
}

.user_table_item_selected{
  background-color: #e69344de;
}

.user_table_item{
  background-color: #333333de;
}

.leaderboard_list table .user_table_item_selected td:first-child,
.leaderboard_list table .user_table_item td:first-child {
  width: 100%;
  text-align: left;
}

.leaderboard_controls {
  display: flex;
  flex-direction: column;
  margin: auto;
  width: 250px;
  align-items: center;
  margin-top: 20px;

  .post_controls_lower{
    display: unset;
  }
}
.leaderboard_pagination {
  display: flex;
  padding: 20px;
  justify-content: space-evenly;
  width: 100%;
  color: white;
  align-items: center;
}
.leaderboard_pagination svg {
  cursor: pointer;
}

/* Hide scrollbar for Chrome, Safari, and Opera */
.leaderboard_body::-webkit-scrollbar {
  display: none !important;
}

.leaderboard_body{
  height: 100%;
  width: 100%;
  overflow: scroll;
  z-index: 1;
  display: flex;
  flex-direction: column;  
  justify-content: center;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For Internet Explorer and Edge */

  .post_window_pop_up{
    width: 90%;
    max-width: 1000px;
  }

  .paginator{
    width: 90%;
    padding-right: 10px;
    max-width: 1000px;
  }
  
  .navigator{
    width: 100%;
    padding: 0%;
    margin: 0;
  }
}

.leaderboard_controls{
  height: 100%;
  min-width: 290px;
  display: none;
  color: white;
}

@media (min-width: 884px) {
  .leaderboard_controls{
    display: unset;
  }
}

@media (max-width: 884px) {
  .leaderboard_body{
    .post_window_pop_up{
      width: 100%;
    }
  }
  
  .leaderboard_body .paginator{
    width: 100%;
  }

  .leaderboard_list{
    width: 100%;
  }
}

.leaderboard_window_main {
  padding: 20px;
  padding-bottom: 20px;
  padding-top: 15px;
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  color: white;
  background-color: #2c2b2bcd;
  backdrop-filter: blur(8px);
}