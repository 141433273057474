.upvote_display {
  display: flex;
  justify-content: right;
  align-items: center;
  width: 100%;
  height: 20px;
  max-width: 100px;
  color: white;
  cursor: pointer;
  margin-bottom: 5px;
}

.upvote_display_text {
  font-size: 14px;
  line-height: 1.5;
  font-weight: 600;
  display: flex;
  flex-direction: column;
  justify-content: right;
  align-items: flex-end;
  width: 100%;
  margin-right: -4px;
}

.upvote_display:hover{
  opacity: 0.7;
  padding-bottom: 2px;
}

.upvote_display svg {
  width: 40px;
  cursor: pointer;
  justify-content: right;
}

@media (max-width: 550px) {
  .upvote_display{
    max-width: 80px;
  }
}
