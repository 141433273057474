.resolved_post > div {
  width: 490px;
  padding: 10px 10px 10px 10px;
  min-height: 100px;
  display: flex;
  flex-direction: column;
  margin: 5px;
  color: white;
  border-radius: 2px;
  justify-content: space-between;

}

.resolved_post h2 {
  font-weight: 600;
  font-size: 22px;
  text-transform: uppercase;
  color: gold;
  text-align: right;
  text-shadow: 2px 1px 2px black;
  line-height: 0.9;
  position: absolute;
  top: 32px;
  right: 10px;
}

.resolved_post {
  margin: auto;
}

.resolved_post_user a:hover {
  text-decoration: underline;
}

.resolved_post_top {
  height: 25px;
}
.resolved_post_top,
.resolved_post_bottom {
  display: flex;
  justify-content: space-between;
}
.resolved_post_top h1 {
  line-height: 1.2;
  font-weight: 600;
  color: white;
  text-overflow: ellipsis;
  width: 340px;
}

.resolved_post_top h1:hover {
  text-decoration: underline;
}
.resolved_post_top a {
  width: 90%;
}
.resolved_post_bottom {
  font-size: 12px;
  color: grey;
}

.resolved_posts .resolved_post,
.resolved_post_answered {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: #2c2b2bcd;
  backdrop-filter: blur(8px);
  margin: 5px;
  padding: 5px;
  width: 90%;
  height: 120px;
  position: relative;
  color: white;
  border-radius: 4px;
  box-shadow: 6px 6px 4px -1px rgba(0, 0, 0, 0.4);
}

.resolved_posts:hover,
.resolved_post_answered:hover {
  position: relative;
  top: 2px;
}


.resolved_posts {
  max-width: 1000px;
}

.resolved_post_hidden {
  opacity: 0.7;
}

.resolved_post_upvotes {
  width: 120px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.resolved_post_upvotes a {
  font-size: 10px;
  color: white;
}

b {
  font-weight: 700;
}

.resolved_posted_by a {
  color: #e69344;
  font-size: 13px !important;
}

.resolved_posted_by:hover {
  opacity: 0.8;
  padding-bottom: 2px;
}

.resolved_posted_by b {
  font-size: 10px;
  color: white;
}

.resolved_post_upvotes > p {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  font-weight: 600;
  height: 30px;
}

.resolved_post_contents {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  height: 100%;
  padding-left: 5px;
  margin-bottom: 5px;
  width: 100%;
  max-width: 100%;
  min-width: 100%;
}

@media (max-width: 550px) {
  .resolved_post_contents {
    max-width: 100% !important;
  }
}


.comments_number {
  
  svg {
    margin-right: -4px;
  }

  span {
    padding-right: 7px;
  }
}

.version_number {
  font-size: 12px;
  padding-left: 2px;
  
  margin-bottom: 3px;
  color: grey;
}

.resolved_post_list_post_controls {
  position: absolute;
  right: 5px;
  top: 5px;
}

.resolved_post_contents h1 {
  font-size: 18px;
  font-weight: 800;
  letter-spacing: 1.2px;
  color: white;
  overflow: hidden;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 1.2;
}

.resolved_post_title,
.resolved_post_title a,
.resolved_post_title h1 {
  width: 100%;
}

.resolved_post_title {
  height: 19px;
  margin-left: -4px;
  margin-top: 5px;
  margin-bottom: 1px;
  flex-grow: 2;
  display: flex;
}

.resolved_post_contents h1:hover {
  cursor: pointer;
  text-decoration: underline;
  opacity: 0.9;
}

.resolved_post_contents > div {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

.resolved_post_contents > div > * {
  margin: 0px 2px 0px 2px;
}

.resolved_post_status {
  background-color: #34495e;
  padding: 3px;
  border-radius: 18px;
}

.resolved_post_category {
  background-color: #e67e22;
  padding: 3px 5px 3px 5px;
  color: white;
  font-size: 12px;
  cursor: pointer;
  margin: 0px 4px 0px 0px;
}

.rrui_tooltip {
  display: none !important;
}

.resolved_post_list_controls_div{
  flex-grow: 1;
  display: flex;
  height: 19px;
}

.resolved_post_list_controls_div .date_time_by {
  font-size: 12px;
  white-space: nowrap;
}

.resolved_post_content_body {
  margin-top: 5px;
  padding-top: 2px;
  height: 20px;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content:center;
  align-items: flex-start !important;
}

.resolved_post_content_body p {
  color: white;
  text-overflow: ellipsis;
  font-weight: 100;
  font-size: 13px;
  white-space: nowrap;
}

.resolved_post_upvotes .resolved_posted_by {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: right;
  align-items: flex-end;
  padding-right: 12px;
  padding-top: 5px;
}

@media (max-width: 899px) {
  .resolved_post_upvotes .resolved_posted_by {
    max-width: 100% !important;
    padding-right: 20px;
  }
}
