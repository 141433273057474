@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i,800,800i&display=swap");
@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap");
html {
  font-family: "Open Sans", sans-serif;
  min-height: 100%;
}

textarea {
  font-family: "Open Sans", sans-serif;
}

.MuiSvgIcon-root {
  font-size: 2.5rem;
}
.MuiSvgIcon-root:hover {
  opacity: 0.9;
}

.makeStyles-root-1 a {
  color: inherit;
}

.ql-fill {
  fill: white !important;
}
.ql-stroke {
  stroke: white !important;
}
.ql-picker {
  color: white !important;
}

.ql-picker-options {
  background-color: #333333;
  border-color: white !important;
}

.ql-snow.ql-toolbar button:hover,
.ql-snow.ql-toolbar button.ql-active {
  outline: 1px solid white !important;
}
.ql-snow.ql-toolbar button:active {
  border: 1px solid black;
}

.top-image {
  background-image: url("assets/current_update_logo.png");
  height: 100px;
  width: 20%;
  max-width: 300px;
  min-width: 100px;
  position: absolute;
  top: 80px;
  right: 10px;
  z-index: 0;
    /* Center and scale the image nicely */
    background-position: top;
    background-repeat: no-repeat;
    background-size:contain;
}

@media (max-width: 600px) {
  .top-image{
    display: none;
  }
}

.bg-image {
  position: fixed;
  background: linear-gradient(#000000a9, #000000a9), url("assets/background_image.png");
  height: 100vh;
  width: 100%;
  filter: blur(8px);
  -webkit-filter: blur(8px);

    /* Center and scale the image nicely */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

@media (max-width: 600px){

}

.MuiSelect-icon {
  color: white;
}

time {
  text-align: center;
}
