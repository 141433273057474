.admin_panel {
  width: 100%;
  display: flex;
  flex-direction: row;
  margin: auto;
  align-items: center;
  justify-content: center;
  color: #34495e;
}

.controls_options {
  display: flex;
  align-items: center;
  justify-content: center;
}

.controls_options p{
  cursor: pointer;
  color: #e69344;
}

.controls_options .MuiIconButton-root {
  padding: 6px;
}

.admin_panel > h1 {
  width: 100%;
  text-align: center;
}

.admin_post,
.admin_user {
  width: 100%;
}
.admin_post > div,
.admin_user > div {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}
.admin_post input,
.admin_user input {
  max-width: 500px;
}
.controls_small,
.controls_big {
  width: 100%;
  margin-bottom: 20px;
  color: white;
}

.controls_small > div,
.controls_big > div {
  margin-bottom: 20px;
}

.controls_small{
  display: unset;
}

.controls_big {
  display: none;
}

.controls_big_content {
  box-shadow: 6px 6px 4px -1px rgba(0, 0, 0, 0.4);
  background-color: #2c2b2bcd;
  backdrop-filter: blur(8px);
  padding: 20px;
}

.admin_panel_left_bar{
  min-width: 290px;
  display: none;
}

.dragging{
  background-color: rgba(124, 124, 124, 0.56);
}

.admin_panel_right{
  overflow: scroll;
  z-index: 0;
  width: 100%;
  height: 100%;
  padding: 20px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For Internet Explorer and Edge */
}

/* Hide scrollbar for Chrome, Safari, and Opera */
.admin_panel_right::-webkit-scrollbar {
  display: none !important;
}

.admin_panel_right_content {
  max-width: 1000px;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.admin_panel .admin_section_details,
.admin_section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-evenly;
}
.admin_section_details > div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.admin_statuses_section_details > div {
  height: 315px;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
}

.admin_statuses_section_details label{
  margin-top: 20px;
}

.admin_post label,
.admin_user label {
  margin-right: 10px;
}

.NewTextAreaWithList{
  display: flex;
  align-items: flex-start;
}

.NewAdminBanner{
  width: 100%;
  display: flex;
  align-items: flex-start;
}

.admin_panel_right_content > h1{
  box-shadow: 6px 6px 4px -1px rgba(0, 0, 0, 0.4);
  border-radius: 4px;
  background-color: #e69244ef;
  backdrop-filter: blur(8px);
}

.header_textarea {
  padding: 10px;
  background-color: #313131;
  box-shadow: 6px 6px 4px -1px rgba(0, 0, 0, 0.4);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  justify-items: flex-start;
}

.header_textarea label{
  text-align: start;
  width: 100%;
}

.NewAdminBanner textarea,
.NewTextAreaWithList textarea{
  padding: 10px;
  width: 100%;
  height: 100%;
  margin-bottom: 20px;
  resize: none;
  background-color: #444;
  box-shadow: 6px 6px 4px -1px rgba(0, 0, 0, 0.4);
  border: none;
  border-radius: 0px 0px 4px 4px;
  color: white;

    /* Define the styles for the scrollbar */
    scrollbar-color: #333 transparent; /* Set the color of the scrollbar track and thumb */
    scrollbar-width: thin; /* Set the width of the scrollbar */
}
.NewAdminBanner textarea:focus,
.NewAdminBanner textarea:active,
.NewTextAreaWithList textarea:focus,
.NewTextAreaWithList textarea:active {
  outline: #333333;
  outline-width: 1px;
  outline-color: #ffffff0a;
}


.NewTextAreaWithList .admin_IP_details{
  width: 400px;
  height: 430px;
  padding-right: 20px;
}

.NewAdminBanner .admin_IP_details{
  height: auto;
  width: 100% !important;
  padding-right: 20px;
}

.NewAdminBanner .admin_IP_details button,
.NewTextAreaWithList .admin_IP_details button{
  background-color: #e67e22;
  box-shadow: 6px 6px 4px -1px rgba(0, 0, 0, 0.4);
}

.NewAdminBanner .admin_IP_details button:hover,
.NewTextAreaWithList .admin_IP_details button:hover{
  background-color: #c36c1f;
  box-shadow: 6px 6px 4px -1px rgba(0, 0, 0, 0.4);
}

.NewAdminBanner .admin_IP_details button:disabled,
.NewTextAreaWithList .admin_IP_details button:disabled{
  background-color: #e67d224e;
  box-shadow: 6px 6px 4px -1px rgba(0, 0, 0, 0.4);
}

.admin_emails textarea,
.admin_IP textarea {
  height: 100px;
  width: 100%;
  resize: none;
}

.admin_emails_details,
.admin_IP_details {
  height: 200px;
}

.react-tabs,
.react-tabs__tab-panel--selected {
  height: 100%;
}

.table_controls_start {
  display: flex;
  width: 100%;
  margin: auto;
  margin-left: 20px;
  text-align: center;
}
.table_controls_start > div{
  margin-right: 10px;
}
.table_controls_start > p{
  padding-top: 5px;
  margin-right: 10px;
}
.table_controls_start_input > input{
  margin-left: 50px;
  margin-right: -60px;
  display: flex;
}

.table_controls {
  display: flex;
  justify-content: space-evenly;
  max-width: 200px;
  margin: auto;
  text-align: center;
}
.table_controls > p,
.table_controls > a {
  color: #e69344;
  text-decoration: underline;
  cursor: pointer;
}
.react-tabs__tab-panel table tr td {
  vertical-align: middle;
}
.react-tabs__tab-panel table tr > * {
  height: 100%;
}
.post_table_title {
  max-height: 30px;
  max-width: 180px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.nice-dates-navigation,
.nice-dates-day {
  color: #111;
}
.nice-dates-popover {
  box-shadow: none;
  border: 1px solid #ddd;
  border-radius: 3px;
  max-width: 230px;
  max-height: 300px;
  transition: none;
  margin-right: 500px;
}
.nice-dates-day {
  max-height: 40px;
}

@media (min-width: 884px) {
  .admin_panel_left_bar{
    display: unset;
  }

  .controls_small{
    display: none;
  }

  .controls_big{
    display: flex;
    flex-direction: column;
  }
}