/* SwitchSelector.css */

.switch-selector {
    display: flex;
    align-items: center;
    width: 240px;
    height: 40px;
    background-color: #464646 !important;
    span{
        font-family: "Roboto", "Helvetica", "Arial", sans-serif;
     text-align: center;
     text-transform: uppercase;
     font-size: 14px;
     font-weight: 500;
    }
  }
  
  .arrow_btn {
    cursor: pointer;
    font-size: 40px !important;
    width: 40px;
    height: 40px;
    padding: 0;
    margin-left: -5px;
    margin-right: -5px;
  }
  

