.post_list {
  /* max-width: 1000px; */
  /* width: 80%; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
}

.posts {
  width: 75%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

@media (max-width: 1000px) {
  .posts {
    width: 100%;
  }
}

@media (max-width: 900px) {
  .posts {
    padding: 10px;
    padding-top: 0px;
  }
  .post,
  .post_answered {
    width: 100% !important;
  }
  .version_number {
    display: none;
  }
}

@media (min-width: 884px){

  .post_list {
    padding-left: 290px;
  }
}


@media (max-width: 350px) {
  .post .categories,
  .post_answered .categories {
    display: none;
  }

  .post .post_title a,
  .post_answered .post_title a {
    width: 90%;
  }
}

.no_posts_found {
  background-color: #333333;
  width: 95%;
  padding: 20px;
  text-align: center;
  color: white;
  margin: 20px;
  font-weight: 600;
  max-width: 1000px;
  border-radius: 4px;
  box-shadow: 4px 4px 4px -1px rgba(0, 0, 0, 0.5),
  1px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
