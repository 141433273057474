.version_number_selector {
  display: flex;
  min-width: 200px;
  justify-content: space-between;
  align-items: center;
}

.version_number_selector .basic-multi-select {
  padding-left: 8px;
  margin-top: 3px;
  margin-right: 12px;
}

.version_tutorial_2 {
  max-width: 100%;
  margin: 10px;
}

.tooltip_modal_contents {
  padding: 20px;
  color: white;
  background-color: #3f3e3e;
}
.tooltip_modal_contents ol {
  list-style: decimal;
  padding: 20px;
}
.tooltip_modal_contents li > div {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.tooltip_modal_contents > h1 {
  font-weight: 600;
  font-size: 22px;
  margin-bottom: 10px;
  text-align: center;
  text-decoration: underline;
}

.ScrollPostContent{
  display: flex;
  justify-content: center;
  padding-bottom: 20px;
}

.version_tutorial_3 {
  margin: auto;
  margin-top: 10px;
  margin-bottom: 10px;
  max-height: 400px;
}
.tooltip_modal_contents li > div > h1 {
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 5px;
}
.tooltip_modal_contents > div {
  line-height: 1.5;
  margin-bottom: 5px;
}
.tooltip_modal_contents > div > b {
  font-weight: 600;
  margin-right: 5px;
  color: #e69344;
}

.post_screen {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: auto;
  width: 100%;
  height: 100%;
}

.create_post_controls{
  height: 100%;
  min-width: 290px;
  display: none;
  color: white;

  .rules_window_pop_up{
    width: 90%;
  }
  
  .comment_window_pop_up_title{
    height: 45px;
    display: flex;
    align-items: center;
    font-size: 20px;
    color: white;
    font-weight: 700;
    background-color: #464646;
  }

  .title_comment_pop_up{
    padding-left: 20px;
  }

  .post_brief{
    background-color: #2c2b2bcd;
    padding: 20px;
    text-align: justify;
    font-size: 13px;
  }
}

/* Hide scrollbar for Chrome, Safari, and Opera */
.create_post_body::-webkit-scrollbar {
  display: none !important;
}

.create_post_body{
  height: 100%;
  width: 100%;
  overflow: scroll;
  z-index: 1;
  display: flex;
  justify-content: center;
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For Internet Explorer and Edge */

  .post_window_pop_up{
    width: 90%;
    max-width: 1000px;
  }
}

@media (min-width: 884px) {
  .create_post_controls{
    display: unset;
  }
}