.control_block {
  font-size: 16px;
  overflow: hidden;
  transition: all 0.2s;
  background-color: #333333;
  padding: 20px;
  padding-bottom: 0;
  color: white;
  margin-bottom: 10px;
}

.control_block > div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.control_block input[type="password"] {
  max-width: 400px;
  width: 60%;
}

.control_block .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 800px) {
}

.arrow {
  cursor: pointer;
  height: 40px;
  position: relative;
  transform: rotate(90deg);
  transition: transform 0.2s;
  width: 20px;
  margin-right: 10px;
  top: -5px;
}
.arrow_flip {
  transform: rotate(-90deg);
  margin-right: 13px;
}
.arrow-top,
.arrow-bottom {
  background-color: white;
  height: 4px;
  left: -5px;
  position: absolute;
  top: 50%;
  width: 100%;
}
.arrow-top:after,
.arrow-bottom:after {
  background-color: #e69344;
  content: "";
  height: 100%;
  position: absolute;
  top: 0;
  transition: all 0.15s;
}
.arrow-top {
  transform: rotate(45deg);
  transform-origin: bottom right;
}
.arrow-top:after {
  left: 100%;
  right: 0;
  transition-delay: 0s;
}
.arrow-bottom {
  transform: rotate(-45deg);
  transform-origin: top right;
}
.arrow-bottom:after {
  left: 0;
  right: 100%;
  transition-delay: 0.15s;
}
.arrow:hover .arrow-top:after {
  left: 0;
  transition-delay: 0.15s;
}
.arrow:hover .arrow-bottom:after {
  right: 0;
  transition-delay: 0s;
}
