.user_details {
  width: 90%;
  background-color: #333333;
  margin: 10px;
  padding: 20px;
}
.user_details > div > * {
  padding: 10px;
}
.user_details > div {
  outline: 1px solid rgb(150, 148, 148);
}

.user_details > h1 {
  font-size: 22px;
  text-align: center;
  font-weight: 600;
  margin-bottom: 20px;
}
.user_details > div span {
  font-weight: 600;
}
.user_details p {
  display: flex;
  justify-content: space-between;
}
.user_details > div > p:nth-child(odd) {
  background-color: #3f3e3e;
}

.user_details_window{
  backdrop-filter: blur(8px);
  background-color: #2c2b2bcd;
  display: flex;
  max-width: 1000px;
  flex-direction: row;
}

.user_details_window_data p{
  white-space: nowrap;
  width: 100%;
  font-weight: 600;
  display: flex;
  padding: 15px;
}

.user_details_window_data span {
  width: 100%;
  text-align: right;
  text-transform: lowercase;
}

.user_details_window_data p:nth-child(even) {
  background-color: #464646;
}

.user_details_window_data p:nth-child(odd) {
  background-color: #808080;
}

.user_details_window_data p:last-child {
  display: none;
}

.user_details_window_data{
  width: 100%;
  padding: 0px;
}

@media (max-width: 884px) {
  .user_details_window_data{
    p:last-child {
      display: flex;
    }
  }

  .parent-container {
    display: none;
  }
}

.parent-container {
  min-width: 200px;
  width: 30%;
  display: flex;
  flex-direction: column;
}

.user_bitmap{
  max-height: 300px;
  height: 90%;
}

.flexible-child {
  flex: 1; /* This child will take up all the remaining space */
  display: flex;
  justify-content: center;
  align-items: center;
}

.fixed-height-child {
  height: 60px; /* Fixed height for the second child */
  display: flex;
  align-items: center; /* Center the content vertically */
  justify-content: center; /* Center the content horizontally */
  padding: 10px;

  span {
    font-size: 16px;
    font-weight: 800;
  }
}

.role_data{
  background-color: orange;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center; /* Center the content vertically */
  justify-content: center; /* Center the content horizontally */
}