.no_comments_found{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50px;
}

.post_comments_list{
  .paginator {
    width: 100%;
    padding-bottom: 20px;
  }

  .navigator{
    width: 100%;
  }
}