.banner {
  width: 95%;
  color: white;
  display: flex;
  flex-direction: column;
  margin: 5px;
  max-width: 1000px;
  z-index: 1;
}
.banner_top {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 5px;
  align-items: center;
  height: 30px;
}
.banner_top h1 {
  font-weight: 600;
  font-size: 20px;
  margin: 5px;
}
.banner_top,
.banner_contents {
  background-color: #333333;
  padding: 10px;
  border-radius: 4px;
  box-shadow: 6px 6px 4px -1px rgba(0, 0, 0, 0.4);
}
.banner_contents p {
  line-height: 1.3;
}
