.post,
.comment {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 5px;
  padding: 5px;
  position: relative;
  color: white;
  border-radius: 4px;
  box-shadow: 6px 6px 4px -1px rgba(0, 0, 0, 0.4);
  margin-bottom: 7px;
  a{
    height: 22px;

    h2 {
      height: 22px;
    }
  }
}

.user_public {
  max-width: 800px;
  margin: auto;
  width: 90%;
  text-align: center;
  margin-bottom: 50px;
}

.user_public_main_body > h1,
.user_public > h1 {
  font-weight: 700;
}

.user_public_main_body{
  height: 100%;
  width: 100%;
  overflow: scroll;
  z-index: 1;
  display: flex;
  flex-direction: column;  
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;
  
  .user_panel{
    margin: auto;
    max-width: 1000px;
  }
}

.user_public_main_body {
  -ms-overflow-style: none !important;  /* IE and Edge */
  scrollbar-width: none !important;     /* Firefox */
}

.user_public_body{
  background-color: #2c2b2bcd;
  backdrop-filter: blur(8px);
  padding: 20px;
  display: flex;
  flex-direction: column;
  
  .paginator {
    width: calc(100%);
    padding: 0;
    padding-left: 10px;
    padding-right: 10px;
  }

  .navigator{
    width: 100%;
  }
}
.user_public_main_body .post,
.user_public_main_body .comment,
.user_public .post,
.user_public .comment {
  display: flex;
  justify-content: flex-start;
  padding: 10px;
  color: white;
  background-color: #3e3d3d47;
}

.user_public .post{
  background-color: #2c2b2bcd;
}

@media (max-width: 700px) {
  .user_public_main_body .post,
  .user_public .post,

  .post_box {
    width: 70%;
  }
}

@media (max-width: 650px) {
  .user_public_main_body .post,
  .user_public .post,
  .post_box {
    width: 60%;
  }
}

@media (max-width: 500px) {
  .user_public_main_body .post,
  .user_public .post,
  .post_box {
    width: 50%;
  }
}

.user_public_main_body .upvote_box,
.user_public .upvote_box {
  height: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  width: 100px;
  min-width: 100px;
  font-weight: 600;
  margin-right: 15px;
}

.user_public_main_body .upvote_box span,
.user_public .upvote_box span {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
}
.comment .upvote_box span {
  font-size: 12px;
}
.comment .upvote_box > div {
  width: 100%;
}
.comment .upvote_box p {
  margin: auto;
  font-size: 16px !important;
}

.user_public_main_body .post_box,
.user_public .post_box {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  height: 100%;
  width: calc(100% - 180px);
}

.user_public_main_body h2,
.user_public .post_box h2 {
  font-weight: 700;
  color: white;
  overflow: hidden;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.user_public_main_body .post_box h2:hover,
.user_public .post_box h2:hover {
  cursor: pointer;
  text-decoration: underline;
  opacity: 0.9;
}

.user_public_main_body .post_box p,
.user_public_main_body .comment p,
.user_public .post_box p,
.user_public .comment p {
  font-size: 12px;
}

.post_box .post_page_categories {
  padding: 0px;
}
.comment:hover {
  position: relative;
  top: 2px;
}

.comment_box span {
  font-size: 14px;
  text-align: left;
  margin-bottom: 20px;
  text-align: justify;
}

.comment_box p {
  font-size: 14px !important;
}

.comment_box {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  width: 100%;
  padding-right: 40px;
  word-break: break-word;
}
.post_box > p,
.post_box > a {
  text-align: left;
}
.comment {
  height: inherit;
  min-height: 55px;
}
.comment .upvote_box {
  min-height: 65px;
}

.user_public_main .upvote_box p,
.user_public .upvote_box p {
  height: 100%;
  display: flex;
  align-items: center;
  font-size: 18px;
}
.control_message,
.control_flags {
  display: flex;
  margin-top: 10px;
  color: #e69344;
  align-items: center;
  justify-content: flex-start;
}
.control_message svg {
  margin-left: 5px;
}
.control_message:hover {
  cursor: pointer;
  text-decoration: underline;
}
.public_controls {
  display: flex;
  justify-content: space-between;
}
.post_left {
  display: flex;
  align-items: flex-start;
  height: 65px;
  max-width: 600px;
  width: 100%;
}
.post_favourite_flags,
.comment_favourite_flags {
  position: absolute;
  right: 10px;
  bottom: 10px;
}
.user_public_delete {
  position: absolute;
  right: 10px;
  top: 10px;
}

.react-tabs__tab--selected {
  color: white;
  background-color: #333333;
  border-color: white;
}
.react-tabs__tab {
  color: white;
}


@media (min-width: 884px) {
  .tablist_user_public{
    display: none;
  }
}

.user_public_body {
  display: flex;
  justify-content: center;

  .comment,
  .post{
    margin: 10px;
    width: calc(100% - 20px) !important;
  }
  
  
}