#drawer {
  background-color: #333333;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 1000;
  overflow-y: auto;
  display: flex;
  justify-items: center;
  align-content: center;
  opacity: 0;
  z-index: -1000;
}

#drawer ul {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 90%;
  margin: auto;
  min-height: 550px;
  max-height: 650px;
}
#drawer ul div {
  width: 100%;
  height: 10vh;
  max-height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #666;
  transition: 0.2s;
  font-size: 18px;
  font-weight: 600;
}
#drawer ul div:hover {
  background-color: rgb(216, 215, 215);
  color: white;
}
#drawer a {
  text-decoration: none;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

#menu-list-grow a {
  color: inherit;
}

.my-node-enter {
  opacity: 0 !important;
  z-index: 1000 !important;
}
.my-node-enter-active {
  opacity: 1 !important;
  transition: opacity 200ms;
  z-index: 1000 !important;
}
.my-node-exit {
  opacity: 1 !important;
  z-index: 1000 !important;
}
.my-node-exit-active {
  opacity: 0 !important;
  transition: opacity 200ms;
  z-index: 1000 !important;
}
.my-node-enter-done {
  opacity: 1 !important;
  z-index: 1000 !important;
}
.modal_scroll_view {
  display: flex;
}

.drawer_nav {
  position: relative;
  display: flex;
  align-items: center;
  width: 80%;
  justify-content: flex-end;
  width: 100%;
  background-color: #333333;
  color: white;
  max-width: 1920px;
  height: 50px;
  margin-bottom: 5px;
}
.hamBurger {
  z-index: 1;
  cursor: pointer;
}

.hamBurgerOpen{
  z-index: 1001;
  cursor: pointer;
}

.drawer_nav > ul {
  display: flex;
  justify-content: space-between;
  width: 95%;
  align-items: center;
  margin: auto;
}
.drawer_nav img {
  max-height: 40px;
  max-width: 166px;
  width: 100%;
}
