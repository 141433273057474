.profile_dashboard {
  max-width: 1000px;
  height: calc( 100vh - 120px);
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
}

.profile_content {
  opacity: 0.5;
}


.user_window_pop_up{
  width: 90%;
  max-width: 1000px;
  margin-bottom: 10px;
  border-radius: 4px;
  overflow: hidden;
  box-shadow: 6px 6px 4px -1px rgba(0, 0, 0, 0.4);
}

.user_window_title_text{
  display: flex;
  width: 100%;
  align-content: space-between;
  justify-content: space-between;
}

.title_comment_pop_up_date{
  font-size: 17px;
}

.profile_pass_settings{
  display: none;
}

@media (max-width: 920px){
  .parent-container {
    display: none;
  }
}

@media (max-width: 884px) {

  .title_comment_pop_up_date{
    font-size: 14px;
  }

  .control_block .header {
    font-size: 16px;
    height: 20px;
    font-weight: 700;
    text-transform: uppercase;
    
    
  }

  .profile_pass_settings{
    display: flex;
    width: 100%;
    max-width: 1000px;
    margin: auto;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
  }

  .user_window_title_text{
    display: flex;
    flex-direction: column;
  }

  .profile_dashboard {
    height: auto;
  }

  .user_window_pop_up{
    border-radius: 0;
    box-shadow: none;
  }
}

.change_password_modal{
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;

  .window_pop_up{
    max-width: 700px;
    backdrop-filter: blur(8px);
    background-color: rgba(240, 248, 255, 0.105);
  }
}


.other_settings_modal{
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;

  .window_pop_up{
    max-width: 700px;
    backdrop-filter: blur(8px);
    background-color: rgba(240, 248, 255, 0.105);
    min-height: 0;
  }

  .login_content{
    div:nth-child(even) {
      background-color: #464646;
    }

    span {
      width: 100%;
    }

    p {
      white-space: nowrap;
      width: 100%;
      font-weight: 600;
      padding: 15px;
      padding-top: 5px;
      padding-bottom: 5px;
      display: flex;
      align-items: center;
      color: white;
    }
    
  
    div:nth-child(odd) {
      background-color: #808080;
    }
    
    button {
      width: 210px;
      border-radius: 0;
      background-color: #bc0000;
    }

     button:hover {
      background-color: #a11616
    }

  }
}

.spinner_settings {
  margin: 0;
  background-color: transparent !important;
  height: 10px;
  margin-top: -120px;
  margin-right: 10px;

  div {
      div:nth-child(2) {
        background-color: white !important;
        max-height: 20px !important;
      }

      div:nth-child(3) {
        background-color: whitesmoke !important;
        max-height: 20px !important;
      }

      div:nth-child(4) {
        background-color: white !important;
        max-height: 20px !important;
      }

      div:nth-child(5) {
        background-color: whitesmoke !important;
        max-height: 20px !important;
      }
  }
  
}